Date.prototype.addDays = function (days) {
	var dat = new Date(this.valueOf());
	dat.setDate(dat.getDate() + days);
	return dat;
};

(function ($) {
	/* jshint expr: true */
	$.fn.extend({
		donetyping: function (callback, timeout) {
			timeout = timeout || 300;
			var timeoutReference,
				doneTyping = function (el) {
					if (!timeoutReference) return;
					timeoutReference = null;
					callback.call(el);
				};
			return this.each(function (i, el) {
				var $el = $(el);
				$el.is(':input') && $el.on('keyup keypress', function (e) {
					if (e.type == 'keyup' && e.keyCode != 8) return;

					if (timeoutReference) clearTimeout(timeoutReference);
					timeoutReference = setTimeout(function () {
						doneTyping(el);
					}, timeout);
				});

				$el.is(':input') && $el.on('change', function (e) {
					if (timeoutReference) clearTimeout(timeoutReference);
					timeoutReference = setTimeout(function () {
						doneTyping(el);
					}, timeout);
				});

				$el.is('textarea') && $el.on('keyup keypress', function (e) {
					if (e.type == 'keyup' && e.keyCode != 8) return;

					if (timeoutReference) clearTimeout(timeoutReference);
					timeoutReference = setTimeout(function () {
						doneTyping(el);
					}, timeout);
				});

				$el.on('paste', function (e) {
					if (timeoutReference) clearTimeout(timeoutReference);
					timeoutReference = setTimeout(function () {
						doneTyping(el);
					}, timeout);
				});
			});
		},
		goTo: function () {
			$('html, body').animate({
				scrollTop: $(this).offset().top + 'px'
			}, 'fast');
			return this; // for chaining...
        },
        focusTextToEnd: function(){
            this.focus();
            var $thisVal = this.val();
            this.val('').val($thisVal);
            return this;
        }
	});
})(jQuery);

var common_app = common_app || {};
common_app = {
	document: $(document),
	body: $(document.body),
	observer: null,
	current_province_id: 0,
	current_district_id: 0,
	current_ward_id: 0,
	current_street_id: 0,
	current_project_id: 0,
    is_requesting: false,
    static_api_upload_url:'https://static123.com/api/upload',
	layoutCommon: function () {
        
	},
	isFacebookApp: function () {
		var ua = navigator.userAgent || navigator.vendor || window.opera;
		return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
	},
	lazyLoadImage: function () {
		if (typeof lozad === 'function') {
			this.observer = lozad('.lazy', {
				rootMargin: '10px 0px', // syntax similar to that of CSS Margin
				threshold: 0.1,
				loaded: function (element) {
					element.classList.add('lazy_done');
					element.classList.remove('lazy');
				}
			});
			this.observer.observe();
		}
    }, 
    moveToTrash: function () {
		$('.js-item-row').on('click', '.js-move-to-trash', function (event) {
			event.preventDefault();

			var object_id = $(this).attr('data-id');
			var object_type = $(this).attr('data-type');
			var object_action = $(this).attr('data-end-action') ? $(this).attr('data-end-action') : '';

			if (!object_id || !object_type) return;
			$.ajax({
				url: base_api_url + '/utility/confirm_trash',
				data: {
					'object_id': object_id,
					'object_type': object_type,
					'object_action': object_action
				},
				type: 'POST',
				dataType: 'json'
			});
		});

		$('.js-btn-confirm-move-to-trash').on('click', function (event) {
			event.preventDefault();

			$('#modal_confirm_trash').modal('hide');

			var object_id = $(this).attr('data-objet-id');
			var object_type = $(this).attr('data-type');
			var object_action = $(this).attr('data-action');
			$.ajax({
				url: base_api_url + '/utility/movetotrash',
				data: {
					'object_id': object_id,
					'object_type': object_type,
					'object_action': object_action
				},
				type: 'POST',
				dataType: 'json'
			});
		});
    },
    media: {
		is_requesting: false,
		can_get_more_medias: true,
		showMediaPopup: function () {
			$('.js-btn-show-image-popup').on('click', function (event) {
                event.preventDefault();
				var type_target = $(this).attr('data-type-target') ? $(this).attr('data-type-target') : 'post';
				var support_choice = $(this).attr('data-choice') ? $(this).attr('data-choice') : 'multiple';
				var insert_to = $(this).attr('data-insert') ? $(this).attr('data-insert') : 'content';
				var input_image_name = $(this).attr('data-input-field-name') ? $(this).attr('data-input-field-name') : 'image_linked_slider';
				var allow_featured_field = $(this).attr('data-allow-featured') ? $(this).attr('data-allow-featured') : 0;

				$.ajax({
					url: base_url + '/api/utility/media',
					data: {
						'data_type': type_target,
						'support_choice': support_choice,
						'insert_to': insert_to,
						'input_name': input_image_name,
						'allow_featured_field': allow_featured_field
					},
					type: 'POST',
					dataType: 'json',
				});
			});
		},
		// PROGRESS FOR UPLOAD
		add_file: function (id, file) {
			/* preparing image for preview */
			if (typeof FileReader !== "undefined") {

				var reader = new FileReader();
				reader.onload = function (e) {
					var file_ext = file.name.split('.').pop().toLowerCase();

					var image_preview = e.target.result;
					var thumbnail_class = 'image';
					if (file_ext == 'png' || file_ext == 'jpg' || file_ext == 'jpeg' || file_ext == 'gif') {
						image_preview = e.target.result;
					} else {
						thumbnail_class = 'file';
						image_preview = base_url + '/images/exts/icon-' + file_ext + '.png';
					}

					var template = '' +
						'<div id="media_item_' + id + '" class="media_item js-media-item ' + thumbnail_class + '" data-file-type="' + thumbnail_class + '" data-extension="' + file_ext + '" data-id="" data-path-url="" data-url="" data-title="" data-description="" data-alt-title="" data-file-name="' + file.name + '" data-width="" data-height="" data-size="" data-created-at="">' +
						'<div class="media_item_preview">' +
						'<div class="media_item_thumbail">' +
						'<img src="' + image_preview + '"></div>' +
						'<span>' + file.name + '</span>' +
						'</div>' +
						'<button type="button" class="check"><span class="media-modal-icon"></span></button>' +
						'<div class="progress">' +
						'<div class="progress-bar progress-bar-success js-progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: 40%">' +
						'<span>40%</span>' +
						'</div>' +
						'</div>' +
						'</div>';
					$('.js-media-list').prepend(template);
				};

				reader.readAsDataURL(file);
			}
		},
		update_file_status: function (id, status, message) {
			$('#media_item_' + id).addClass(status);
		},
		update_file_progress: function (id, percent) {
			$('#media_item_' + id).find('.js-progress-bar').width(percent);
			$('#media_item_' + id).find('.js-progress-bar span').html(percent);
		},
		bgLoadMoreImages: function (offset, limit, action) {
			var self = this;
			offset = offset || 0;
			limit = limit || 20;
			action = action || 'append';
			// dang request thi ko lam gi het
			if (self.is_requesting) return;
			self.is_requesting = true;

			var filetype = $('.js-select-filetype').val();
			var time = $('.js-select-time').val();
			var searching = $('.js-input-filter-media').val();

			$.ajax({
				url: 'https://static123.com/api/media',
				data: {
					'start_offset': offset,
					'limit': limit,
					'filetype': filetype,
					'time': time,
					'action': action,
					'search': searching,
					'source':'xsmb123',
					'user_id':current_user_id || 1
				},
				type: 'POST',
				dataType: 'json',
				success: function (response) {
					self.is_requesting = false;
					self.initEventMediaModule();
				},
				error: function (response) {
					self.is_requesting = false;
				}
			});
		},
		reorderImage: function () {
			if ($(".js-upload-progress-wrapper").find('.media').length) {
				$(".js-upload-progress-wrapper").sortable({
					tolerance: 'pointer'
				});
			}
			if ($(".js-upload-linked-wrapper").find('.media').length) {
				$(".js-upload-linked-wrapper").sortable({
					tolerance: 'pointer'
				});
			}
		},
		popupMediaModule: function () {
			var self = this;
			self.bgLoadMoreImages();
			//initEventMediaModule();

			$('.js-media-list').on('scroll', function () {
				var offset_begin_tracked = 160;
				if ($(this).scrollTop() + $(this).innerHeight() + offset_begin_tracked >= $(this)[0].scrollHeight) {

					// nếu còn có thể get media thì mới cho get ko cứ phải ajax hoài
					if (self.can_get_more_medias) {
						self.bgLoadMoreImages($('.js-media-list .js-media-item').length, 20);
					}
				}
			});

			$('.js-select-filetype, .js-select-time').on('change', function () {
				// load lại từ đầu
				self.bgLoadMoreImages(0, 30, 'replace');
			});

			$('.js-button-filter-media').on('click', function () {
				self.bgLoadMoreImages(0, 30, 'replace');
			});

			$('.js-input-filter-media').donetyping(function () {
				self.bgLoadMoreImages(0, 30, 'replace');
			});

			// Xử lí insert image
			$('.js-button-insert-media').on('click', function (event) {
				event.preventDefault();

				var support_choice = $('.js-media-list').attr('data-choice') ? $('.js-media-list').attr('data-choice') : 'single';
				var insert_to = $(this).attr('data-insert-to');
				var input_name = $(this).attr('data-input-field-name');
				var allow_featured_field = $(this).attr('data-allow-featured-field');


				if (support_choice == 'single') {
					var dom_insert_to = '.' + insert_to;
					if (insert_to.charAt(0) == '.' || insert_to.charAt(0) == '#') {
						dom_insert_to = insert_to;
					}
					var parent_el = $(dom_insert_to).parent();
					var input_value_el = parent_el.find('.js-input-value');
					//$('.js-media-item.selected').each(function(index, el){
					$('.js-selected-image-wrap .js-image-selected').each(function (index, el) {
						var media_item = $(el);
						var image_url = media_item.attr('data-url');
						var image_path = media_item.attr('data-path-url');
						var file_type = media_item.attr('data-file-type');
						var extension = media_item.attr('data-extension');
						var filename = media_item.attr('data-file-name');

						var one_image_html = '' +
							'<div class="one_featured_image_src js-one-image-preview" style="background:url(' + image_url + ') no-repeat center center; background-size:cover"></div>' +
							'<a class="remove-image js-remove-one-image"><span><i class="fa fa-trash" aria-hidden="true"></i> Xóa hình này</span></a>';
						if (file_type == 'file') {
							var icon_preview = base_url + '/images/exts/icon-' + extension + '.png';
							one_image_html = '' +
								'<div class="one_featured_image_src js-one-image-preview">' +
								'<img src="' + icon_preview + '"/> <span>' + filename + '</span>' +
								'</div>' +
								'<a class="remove-image js-remove-one-image"><span><i class="fa fa-trash" aria-hidden="true"></i> Xóa file này</span></a>';
						}

						$(dom_insert_to).html(one_image_html);

						parent_el.addClass('upload_done');
						input_value_el.val(image_path);

						// bind event
						$(dom_insert_to).on('click', '.js-remove-one-image', function (event) {
							event.preventDefault();

							$(dom_insert_to).html("");
							parent_el.removeClass('upload_done');
							input_value_el.val('');
						});
					});
				}

				if (support_choice == 'multiple') {
					// trường hợp editor
					if (insert_to == 'js-editor-content') {
						var tinymce_id = $('.' + insert_to).attr('data-insert-id') || $('.' + insert_to).attr('id');
						var html_insert = '';
						//$('.js-media-item.selected').each(function(index, el){
						$('.js-selected-image-wrap .js-image-selected').each(function (index, el) {

							var media_item = $(el);
							var image_url = media_item.attr('data-url');
							var image_title = media_item.attr('data-title');
							var image_description = media_item.attr('data-description');
							var image_alt = media_item.attr('data-alt-title');
							var image_id = media_item.attr('data-id');

							var image_tag = '<figure><img src="' + image_url + '" alt="' + image_alt + '" title="' + image_title + '">';
							if (image_description) {
								image_tag += '<figcaption>' + image_description + '</figcaption>';
							}
							image_tag += '</figure>';

							html_insert += image_tag;
						});

						tinymce.get(tinymce_id).execCommand('mceInsertContent', false, html_insert + '\n');
					} else {
						//$('.js-media-item.selected').each(function(index, el){
						$('.js-selected-image-wrap .js-image-selected').each(function (index, el) {
							var media_item = $(el);
							var image_url = media_item.attr('data-url');
							var image_path = media_item.attr('data-path-url');
							var image_id = media_item.attr('data-id');
							var image_title = media_item.attr('data-title');
							var image_description = media_item.attr('data-description');
							var image_alt_title = media_item.attr('data-alt-title');
							var image_width = media_item.attr('data-width');
							var image_height = media_item.attr('data-height');
							var image_size = media_item.attr('data-size');
							var item_image_html = '' +
								'<div class="media" id="media_' + image_id + '">' +
								'<div class="media-left">' +
								'<div class="upload_thumb" style="background:url(' + image_url + ') center no-repeat; background-size: cover;"></div>' +
								'</div>' +
								'<div class="media-body">' +
								'<span class="delete_image js-delete-image"><i class="fa fa-trash-o" aria-hidden="true"></i> Xóa hình này</span>' +
								'<input type="hidden" name="' + input_name + '[' + image_id + ']" value="' + image_path + '"/>' +
								'<input type="hidden" name="image_titles[' + image_id + ']" value="' + image_title + '"/>' +
								'<input type="hidden" name="image_descriptions[' + image_id + ']" value="' + image_description + '"/>' +
								'<input type="hidden" name="image_alt_titles[' + image_id + ']" value="' + image_alt_title + '"/>' +
								'<input type="hidden" name="image_widths[' + image_id + ']" value="' + image_width + '"/>' +
								'<input type="hidden" name="image_heights[' + image_id + ']" value="' + image_height + '"/>' +
								'<input type="hidden" name="image_sizes[' + image_id + ']" value="' + image_size + '"/>';
							if (allow_featured_field) {
								item_image_html += '<div class="featured_image">' +
									'<input id="label_' + image_id + '" type="radio" name="featured_image" value="' + image_path + '" /> <label for="label_' + image_id + '">Hình đại diện</label>' +
									'</div>';
							}

							item_image_html += '</div>' +
								'</div>';

							var found = false;
							$('.' + insert_to + ' .media').each(function (index, el) {
								if ($(el).attr('id') == 'media_' + image_id) {
									found = true;
									return false;
								}
							});
							if (!found) {
								$('.' + insert_to).append(item_image_html);
							}

							// bind event cho xóa item
							self.removeChooseImage();
							self.reorderImage();
						});
					}
				}

				$('.js-model-media-popup').modal('hide');
            });
            
			$('.js-drag-and-drop-zone').dmUploader({
				url:  common_app.static_api_upload_url,
				dataType: 'json',
				//allowedTypes: 'doc|docx|pdf|xls|xlsx|ppt|pptx|image\/*',
				//extFilter: 'jpg;png;gif;jpeg;JPG;JPEG;PNG;doc;docx;pdf;xls;xlsx;ppt;pptx',
				allowedTypes: '*',
				extFilter: null,
                maxFileSize: 150000000,
                extraData: {
                    'source':'xsmb123',
                    'source_url':window.location.href,
                    'user_id': current_user_id,
                    'from':''
                },
				onInit: function () {

				},
				onBeforeUpload: function (id) {
					self.update_file_status(id, 'uploading', 'Uploading...');
				},
				onNewFile: function (id, file) {
					self.add_file(id, file);
					$('.js-nav-tabs a[href="#library_media"]').tab('show');
				},
				onComplete: function () {
					// khởi tạo lấy mấy event click cho các item mới được add vô
					self.initEventMediaModule();
				},
				onUploadProgress: function (id, percent) {
					var percentStr = percent + '%';
					self.update_file_progress(id, percentStr);
				},
				onUploadSuccess: function (id, data) {
					$('#media_item_' + id).addClass('upload_done');
					self.update_file_status(id, 'selected', 'Upload Complete');
					self.update_file_progress(id, '100%');
					/*jshint -W069 */
					args = []; //new Array(); 
					if(data.file_type =='other'){
						args['url'] = data.file_url;
					}else{
						args['url'] = data.full_image_900_url;
					}
					args['path_url'] = data.image_path;
					args['filename'] = data.filename;
					args['created_at'] = data.created_at;
					args['size'] = data.size;
					args['width'] = data.width;
					args['height'] = data.height;
					args['description'] = '';
					args['title'] = '';
					args['alt_title'] = '';
					args['id'] = data.image_id;
					args['filetype'] = data.file_type;
					args['extension'] = data.extension;

					// fill lại info cho cái selected ở media info left
					self.fillMediaInfo(args);
					self.addSelectedImageToPreview(args);

					// set lại value mới sau khi upload hình thành công
					if (data.file_type == 'image') {
						$('#media_item_' + id).find('img').attr('src', data.full_image_900_url);
					} else {
						var image_preview = base_url + '/images/exts/icon-' + data.extension + '.png';
						$('#media_item_' + id).find('img').attr('src', image_preview);
					}
					$('#media_item_' + id).attr('data-id', data.image_id)
						.attr('data-url', data.full_image_900_url)
						.attr('data-path-url', data.image_path)
						.attr('data-width', data.width)
						.attr('data-height', data.height)
						.attr('data-size', data.size)
						.attr('data-created-at', data.created_at)
						.attr('id', 'media_item_' + data.image_id)
						.attr('data-file-type', data.file_type)
						.attr('data-extension', data.extension);
				},
				onUploadError: function (id, message) {
					console.log('Failed to Upload file #' + id + ': ' + message);
					self.update_file_status(id, 'error', message);
				},
				onFileTypeError: function (file) {
					console.log('File \'' + file.name + '\' cannot be added: must be an image');
				},
				onFileSizeError: function (file) {
					alert('File \'' + file.name + '\' không thể upload: tối đa 50mb/file');
				},
				onFallbackMode: function (message) {
					alert('Browser not supported(do something else here!): ' + message);
				}
			});
		},
		fillMediaInfo: function (args) {
			/*jshint sub:true*/
			args = args || [];
			args['url'] = args['url'] || '';
			args['filename'] = args['filename'] || '';
			args['created_at'] = args['created_at'] || '';
			args['size'] = args['size'] || '';
			args['width'] = args['width'] || '';
			args['height'] = args['height'] || '';
			args['description'] = args['description'] || '';
			args['title'] = args['title'] || '';
			args['alt_title'] = args['alt_title'] || '';
			args['id'] = args['id'] || '';
			args['filetype'] = args['filetype'] || '';
			args['extension'] = args['extension'] || '';

			if (args['filetype'] == 'image') {
				$('.js-media-info .media_info_thumbnail img').attr('src', args['url']);
			} else {
				var icon_preview = base_url + '/images/exts/icon-' + args['extension'] + '.png';
				$('.js-media-info .media_info_thumbnail img').attr('src', icon_preview);
			}
			$('.js-media-info .filename').html(args['filename']);
			$('.js-media-info .uploaded').html(args['created_at']);

			if (args['size'] != 0) {
				$('.js-media-info .file-size').removeClass('hidden');
				$('.js-media-info .file-size').html(args['size']);
			} else {
				$('.js-media-info .file-size').addClass('hidden');
			}

			if (args['width'] != 0) {
				$('.js-media-info .dimensions').removeClass('hidden');
				$('.js-media-info .dimensions').html(args['width'] + ' x ' + args['height']);
			} else {
				$('.js-media-info .dimensions').addClass('hidden');
			}

			$('.js-media-info .js-image-input-url').val(args['url']);
			$('.js-media-info .js-image-input-description').val(args['description']);
			$('.js-media-info .js-image-input-title').val(args['title']);
			$('.js-media-info .js-image-input-alt-text').val(args['alt_title']);

			$('.js-media-info .js-move-to-trash').attr('data-id', args['id']);
			$('.js-input-image-id').val(args['id']);
			$('.js-media-detail').removeClass('hide');
		},
		updateImageInfo: function () {

			var image_title = $('.js-image-input-title').val();
			var image_description = $('.js-image-input-description').val();
			var image_alt = $('.js-image-input-alt-text').val();
			var image_id = $('.js-input-image-id').val();
			// chỉnh sửa lại data cho từng cái media bên trái luôn :(
			$('#media_item_' + image_id)
				.attr('data-alt-title', image_alt)
				.attr('data-description', image_description)
				.attr('data-title', image_title);

			$.ajax({
				url: base_api_url + '/image/update',
				data: {
					'title': image_title,
					'description': image_description,
					'alt_title': image_alt,
					'image_id': image_id
				},
				type: 'POST',
				dataType: 'json'
			});
		},
		addSelectedImageToPreview: function (args) {
			/*jshint sub:true*/
			args = args || [];
			args['url'] = args['url'] || '';
			args['path_url'] = args['path_url'] || '';
			args['filename'] = args['filename'] || '';
			args['created_at'] = args['created_at'] || '';
			args['size'] = args['size'] || '';
			args['width'] = args['width'] || '';
			args['height'] = args['height'] || '';
			args['description'] = args['description'] || '';
			args['title'] = args['title'] || '';
			args['alt_title'] = args['alt_title'] || '';
			args['id'] = args['id'] || '';
			args['filetype'] = args['filetype'] || '';
			args['extension'] = args['extension'] || '';


			var total_image = $('.js-selected-image-wrap-inner').find('.js-image-selected').length;
			/*jshint -W014 */
			var image_selected_html =
				'<div class="image-selected js-image-selected" id="image-selected-' + (args['id']) + '" data-url="' + args['url'] + '" data-path-url="' + args['path_url'] + '" data-id="' + args['id'] + '" data-title="' + args['title'] + '" data-description="' + args['description'] + '" data-alt-title="' + args['alt_title'] + '" data-width="' + args['width'] + '" data-height="' + args['height'] + '" data-size="' + args['size'] + '">' +
				'<img src="' + args['url'] + '" style="width:40px; height:auto">' +
				'</div>';

			$('.js-info-inner').removeClass('hidden');
			$('.js-info-inner').find('.js-number-image').html(total_image + 1);

			$(image_selected_html).appendTo('.js-selected-image-wrap-inner');
			// thêm hình vào phần review
			$('.js-selected-image-wrap').on('click', '.js-image-selected', function (event) {
				var _self = $(this);
				var image_id = $(this).attr('data-id');

				$('.js-selected-image-wrap-inner').find('.js-image-selected').removeClass('selected');
				_self.addClass('selected');

				$('.js-media-list .js-media-item').each(function (index, el) {
					if ($(el).attr('data-id') == image_id) {
						$('.js-media-list .js-media-item').removeClass('detail');
						$(el).addClass('detail');
					}
				});
			});
		},
		removeSelectedImageInPreview: function (args) {
			/*jshint sub:true*/
			args = args || [];
			args['id'] = args['id'] || '';
			if (args['id'] == '') return;

			$('.js-selected-image-wrap-inner').find('.js-image-selected').each(function (index, el) {

				if ($(el).attr('data-id') == args['id']) {
					$(el).remove();
					var total_image = $('.js-selected-image-wrap-inner').find('.js-image-selected').length;
					$('.js-info-inner').find('.js-number-image').html(total_image);
					if (total_image == 0) {
						$('.js-info-inner').addClass('hidden');
					}
				}
			});
		},
		removeChooseImage: function () {
			$('.js-remove-one-image').on('click', function (event) {
				event.preventDefault();
				var _self = $(this);
				swal({
					title: 'Bạn chắc chứ?',
					text: "Bạn chắc muốn xóa hình này chứ",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK, Xóa nó!',
					cancelButtonText: 'Đóng'
				}).then(function () {
					_self.parent().parent().removeClass('upload_done');
					_self.parent().parent().find('.js-input-value').val('');
					_self.parent().html("");

					swal(
						'Deleted!',
						'Hình đã xóa thành công.',
						'success'
					);
				});

			});

			$('.js-delete-image').on('click', function (event) {
				event.preventDefault();
				var _self = $(this);
				swal({
					title: 'Bạn chắc chứ?',
					text: "Bạn chắc muốn xóa hình này chứ",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'OK, Xóa nó!',
					cancelButtonText: 'Đóng'
				}).then(function () {
					_self.closest('.media').remove();

					swal(
						'Deleted!',
						'Hình đã xóa thành công.',
						'success'
					);
				});
			});
		},

		initEventMediaModule: function () {
			var self = this;
			$('.js-media-item').on('click', function (event, data) {
				event.preventDefault();
				event.stopPropagation();

				var _self = $(this);
				// đầu tiên phải kiểm tra xem có cho chọn nhiều hay không
				var support_choice = $('.js-media-list').attr('data-choice') ? $('.js-media-list').attr('data-choice') : 'single';
				var support_can_choice = $('.js-media-list').attr('data-can-choice') ? $('.js-media-list').attr('data-can-choice') : 'image';

				// kiểm tra xem có cho phép chòn các loại file khác hay không
				if (support_can_choice == 'image' && !_self.hasClass('image')) {
					alert('Bạn chỉ được chọn hình ảnh');
					return;
				}
				if (support_choice == 'single') {
					$('.js-media-item').removeClass('selected');
				}

				// Xóa hết các detail khác đi

				$('.js-media-list .js-media-item').removeClass('detail');

				var image_url = _self.attr('data-url');
				var image_path_url = _self.attr('data-path-url');
				var image_title = _self.attr('data-title');
				var image_description = _self.attr('data-description');
				var image_alt_title = _self.attr('data-alt-title');
				var image_filename = _self.attr('data-file-name');

				var image_width = _self.attr('data-width');
				var image_height = _self.attr('data-height');
				var image_size = _self.attr('data-size');
				var image_created_at = _self.attr('data-created-at');
				var image_id = _self.attr('data-id');
				var file_type = _self.attr('data-file-type');
				var extension = _self.attr('data-extension');

				// fill data
				/*jshint sub:true*/
				var args = []; //new Array();

				args['url'] = image_url;
				args['path_url'] = image_path_url;
				args['filename'] = image_filename;
				args['created_at'] = image_created_at;
				args['size'] = image_size;
				args['width'] = image_width;
				args['height'] = image_height;
				args['description'] = image_description;
				args['title'] = image_title;
				args['alt_title'] = image_alt_title;
				args['id'] = image_id;
				args['filetype'] = file_type;
				args['extension'] = extension;

				// xem thông tin hình
				self.fillMediaInfo(args);
				_self.addClass('detail');
				$('.js-media-detail').removeClass('hide');

				// xử lí phần selected
				// nếu user dùng CMD | CTRL hoặc là call tự động thì cho phép xử lí cái chọn luôn

				if (event.metaKey || event.ctrlKey || typeof data !== 'undefined') {

					if (!_self.hasClass('selected')) {
						_self.addClass('selected');
						self.addSelectedImageToPreview(args);
					} else {
						_self.removeClass('selected');
						self.removeSelectedImageInPreview(args);
					}
				} else {

					$('.js-media-item').removeClass('selected');
					$('.js-info-inner .js-remove-all').trigger('click');
					if (!_self.hasClass('selected')) {
						_self.addClass('selected');
						self.addSelectedImageToPreview(args);
					} else {
						_self.removeClass('selected');
						self.removeSelectedImageInPreview(args);
					}
				}

				return false;
			});

			$('.js-media-list').on('click', '.js-media-item .check', function (e) {
				e.stopPropagation();

				$(this).closest('.js-media-item').trigger('click', [{
					'from_trigger': 1
				}]);
				$(this).closest('.js-media-item').removeClass('selected');
				$(this).closest('.js-media-item').removeClass('detail');

			});

			$('.js-media-detail .js-image-input-description').donetyping(function () {
				var image_id = $('.js-input-image-id').val();
				$('#media_item_' + image_id).attr('data-description', $(this).val());
				$('#image-selected-' + image_id).attr('data-description', $(this).val());
				self.updateImageInfo();
			});

			$('.js-media-detail .js-image-input-title').donetyping(function () {
				var image_id = $('.js-input-image-id').val();
				$('#image-selected-' + image_id).attr('data-title', $(this).val());
				self.updateImageInfo();
			});

			$('.js-media-detail .js-image-input-alt-text').donetyping(function () {
				var image_id = $('.js-input-image-id').val();
				$('#image-selected-' + image_id).attr('data-alt-title', $(this).val());
				self.updateImageInfo();
			});

			// xóa tất cả selected
			$('.js-info-inner .js-remove-all').on('click', function (event) {
				$('.js-selected-image-wrap-inner').html("");

				$('.js-info-inner').find('.js-number-image').html(0);
				$('.js-media-list .js-media-item').removeClass('selected');
				$('.js-info-inner').addClass('hidden');
			});
		},
		init: function () {
			this.removeChooseImage();
			this.reorderImage();
			this.showMediaPopup();
		}
	},
	initWithWindow: function () {
		this.lazyLoadImage();
	},
	init: function init() {
		if (this.isFacebookApp()) {
			this.body.addClass('fb_inside_app');
		}
        this.layoutCommon();
        this.moveToTrash();
        // khởi tạo media
		this.media.init();
	}
};

// khởi tạo các hàm chạy trước JQUERY
common_app.initWithWindow();

// khởi tạo các hàm sau khi jquery load
$(document).ready(function () {
	// init app
	common_app.init();

	/* XỬ LÝ AJAX
	XỬ LÝ MỌI RESPONSE TỪ AJAX VỀ Ở ĐÂY
	*/
	$(document).ajaxSend(function (e, request, settings) {
		if (window.show_loading) {
			$('#loading').removeClass('hidden');
		}
	});

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
		}
	});

	$(document).ajaxComplete(function (event, xhr, settings) {
		// reinit facebook
		$('.preload').remove();
		$('#loading').addClass('hidden');
		var jBody = $(document.body);
		window.show_loading = false;
		if (!xhr.responseText) return;
		var response = $.parseJSON(xhr.responseText);

		if (response.code != 200) {
			if (response.code == 500) {
				if (response.element) {
					$(response.element).find('.js-btn-hoan-tat').text(response.button_text);
				}

				if(response.data){
					var jsonData = response.data;
					for (var obj in jsonData) {
						toastr.error(jsonData[obj][0], "Lỗi");
					}
				}
				return;
			}
			if (response.code == 403) {
				$.ajax({
					type: "GET",
					url: base_api_url + '/utility/token',
					success: function (response) {
						if (jBody.hasClass('js-page-dashboard')) {
							toastr.info("Vui lòng thử lại", "Thông báo");
						}

						$('meta[name="_token"]').attr('content', response.token);

						$.ajaxSetup({
							headers: {
								'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
							}
						});
					}
				});
			}
		}
		if (typeof response.action == 'undefined' || response.action == '') return;
		var timeout;
		$.each(response.action.trim().split(' '), function (index, action) {
			if (action == 'close_popup') {
				$('#' + response.popup_id).modal('hide');
			}

			if (action == 'show_popup') {
				jBody.append(response.html);

				$('#' + response.popup_id).on('hidden.bs.modal', function () {
					$(this).remove();
				});

                $('#' + response.popup_id).modal();
                
                if (response.popup_id == 'modal_confirm_trash') {
					common_app.moveToTrash();
                }
                
                if (response.popup_id == 'add_photo_Modal') {
					common_app.media.popupMediaModule();
				}
			}

			if (action == 'redirect') {
				timeout = response.action.trim().split(' ').length > 1 ? 3000 : 500;
				setTimeout(function () {
					window.location.href = decodeURIComponent(response.link);
				}, timeout);
            }
			if (action == 'redirect_suddenly') {
				window.location.href = decodeURIComponent(response.link);
            }
            
			if (action == 'redirect_auth') {
				window.location.href = decodeURIComponent(response.link);
			}

			if (action == 'reload') {
				timeout = response.action.trim().split(' ').length > 1 ? 3000 : 500;
				setTimeout(function () {
					window.location.reload();
				}, timeout);
			}

			if (action == 'alert') {
				alert(response.message);
			}

			if (action == 'sweetalert') {
				swal({
					title: response.title,
					html: response.message,
					type: response.command,
					timer: 3000,
					showConfirmButton: false
				});
			}

			if (action == 'alert_message') {
				alert(response.message);
			}

			if (action == 'alert_message_reload') {
				alert(response.message);
				setTimeout(function () {
					window.location.reload();
				}, 3000);
			}

			if (action == 'replace') {
                if (action == 'replace') {
                    if (response.js_class == '.js-media-list') {
                        $(response.js_class).html(response.html);
                        common_app.media.initEventMediaModule();
                    } else {
                        $(response.js_class).html(response.html);
                    }
                }
			}
			if (action == 'replacewith') {
				$(response.js_class).replaceWith(response.html);
			}

			if (action == 'append') {
                $(response.js_class).append(response.html);

                if (response.js_class == '.js-media-list') {
					common_app.media.initEventMediaModule();
					common_app.moveToTrash();
				}
                
				if (typeof FB != 'undefined') {
					FB.XFBML.parse(document.getElementById('content-wrapp'));
				}

				if (typeof common_app.observer != 'undefined' && common_app.observer) {
					common_app.observer.observe();
				}
			}

			if (action == 'scroll_to_top') {
				$("html, body").animate({
					scrollTop: ($('body').offset().top) + 'px'
				}, "slow");
			}

			if (action == 'get_token') {
				$('meta[name="_token"]').attr('content', response.token);
				$.ajaxSetup({
					headers: {
						'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
					}
				});
			}

			if (action == 'like_action') {
				toastr.options = {
					"closeButton": true,
					"showDuration": "300",
					"hideDuration": "3000",
					"timeOut": "5000"
				};

				if (response.command == 'success') {
					toastr.success(response.message, 'Thông báo');
				} else if (response.command == 'info') {
					toastr.info(response.message, 'Thông báo');
				} else if (response.command == 'waring') {
					toastr.warning(response.message, 'Thông báo');
				} else if (response.command == 'error') {
					toastr.error(response.message, 'Thông báo');
				}
			}

			if (action == 'toastr') {
				toastr.options = {
					"closeButton": true,
					"showDuration": "300",
					"hideDuration": "2000",
					"timeOut": "5000"
				};
				if (response.command == 'success') {
					toastr.success(response.message, response.title);
				} else if (response.command == 'info') {
					toastr.info(response.message, response.title);
				} else if (response.command == 'waring') {
					toastr.warning(response.message, response.title);
				} else if (response.command == 'error') {
					toastr.error(response.message, response.title);
				} else {
					toastr.info(response.message, response.title);
				}
			}

			if (action == 'replace_array_html') {
				$.each(response.responses, function (key, html) {
					$(key).html(html);
				});
			}

			if (action == 'logged_in') {
				
			}

			if (action == 'rating') {
				$('.js-rating-social-wrap').find('.js-rating-point').html(response.rating_average);
				$('.js-rating-social-wrap').find('.js-rating-count').html(response.rating_sum);
				$('.js-rating-social-wrap').find('.js-rating-label-result').html(response.rating_text);
			}

			if(action == 'data_medias'){
				var $html = '';
				$.each(response.data, function(index, $image){
					$image_url = $image.full_image_900_url;
					$file_url = $image.image_url;
					if($image.file_type != 'image'){
						$image_url = base_url + '/images/exts/icon-'+$image.extension+'.png';
						$file_url = $image.file_url;
					}
					$html += '<div id="media_item_'+$image.id+'" class="media_item js-media-item '+$image.file_type+'" data-file-type="'+$image.file_type+'" data-extension="'+$image.extension+'" data-id="'+$image.id+'" data-path-url="'+$image.image_path+'" data-url="'+$file_url+'" data-title="'+$image.filename+'" data-description="'+$image.description+'" data-alt-title="'+$image.alt_title+'" data-file-name="'+$image.filename+'" data-width="'+$image.width+'" data-height="'+$image.height+'" data-size="'+$image.size+'" data-created-at="'+$image.created_at+'">'+
								'<div class="media_item_preview">'+
									'<div class="media_item_thumbail">'+
										'<img src="'+$image_url+'">';
										if($image.file_type == 'file'){
											$html += '<span>'+$image.original_filename+'</span>';
										}
										
					$html +=		'</div>'+
								'</div>'+
								'<button type="button" class="check"><span class="media-modal-icon"></span></button>'+
							'</div>';
				});
				$('.js-media-list').append($html);
				common_app.media.initEventMediaModule();
				common_app.moveToTrash();
			}
		});
	});
    
});