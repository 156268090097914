Number.prototype.formatDot = function(n, x){
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
	return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
};
// INIT FRONT
var front_app = front_app || {};
front_app = {
    is_requesting: false,
    notification_sound:false,
    has_class_next:{},
    keno_current_done: false,
    keno_next: false,
    quaythutimeout: false,
    number_quay_timout: false,
    gen_number_quay_timout: false,
	layoutFront: function () {
        var self = this;
        
        var enabledDays = $('.js-date-picker').attr('data-weekday') || [];
        var province_id = $('.js-date-picker').attr('data-province');
        var mien_id = $('.js-date-picker').attr('data-mien');
        $('.js-date-picker').datepicker({
            language: 'vi',
            maxDate: new Date(),
            dateFormat: 'd/m/yyyy',
            onRenderCell: function (date, cellType) {
                if (cellType == 'day') {
                    var day = date.getDay(),
                        isDisabled = enabledDays.indexOf(day) != -1 ? 0 : 1;

                    return {
                        disabled: isDisabled
                    };
                }
            },
            onSelect: function onSelect(fd, date) {
                var selected_day = date.getFullYear() +'-'+self.pad(date.getMonth()+1,2)+'-'+self.pad(date.getDate(), 2);

                $.ajax({
                    type:'POST',
                    url:base_api_url+'get/post/xoso',
                    data:{ngay:selected_day, 'province_id':province_id, 'mien_id':mien_id},
                });
            }
        });

        $('.js-form-date-picker').datepicker({
            language: 'vi',
            maxDate: new Date(),
            dateFormat: 'd/m/yyyy',
            autoclose:true,
            onSelect: function onSelect(fd, date, inst) {
                if(date){
                    var selected_day = date.getFullYear() +'-'+self.pad(date.getMonth()+1,2)+'-'+self.pad(date.getDate(), 2);
    
                    $.ajax({
                        type:'POST',
                        url:base_api_url+'get/province',
                        data:{ngay:selected_day},
                    });
                }

                $('.js-form-date-picker').blur();
            }
        });
        
        $('.js-other-date-picker').each(function(index, el){
            var province_id = $(el).attr('data-province-id') || 0;
            var mien_id = $(el).attr('data-mien-id') || 0;
            var enabledDays = $(el).attr('data-weekday') || [];

            $('.js-other-date-picker').datepicker({
                language: 'vi',
                maxDate: new Date(),
                dateFormat: 'd/m/yyyy',
                autoclose:true,
                onRenderCell: function (date, cellType) {
                    if (cellType == 'day') {
                        var day = date.getDay(),
                            isDisabled = enabledDays.indexOf(day) != -1 ? 0 : 1;
    
                        return {
                            disabled: isDisabled
                        };
                    }
                },
                onSelect: function onSelect(fd, date, inst) {
                    if(date){
                        var selected_day = date.getFullYear() +'-'+self.pad(date.getMonth()+1,2)+'-'+self.pad(date.getDate(), 2);
        
                        $.ajax({
                            type:'POST',
                            url:base_api_url+'get/post/xoso',
                            data:{ngay:selected_day, 'province_id':province_id, 'mien_id':mien_id},
                        });
                    }
    
                    $('.js-other-date-picker').blur();
                }
            });
        });


        $('.js-toc-list a').on('click', function(e){
            e.preventDefault();

            var id = $(this).attr('href');
            $("html, body").animate({
                scrollTop: ($(id).offset().top - 40) + 'px'
            }, "slow");
        });

        $(".js-btn-load-more-keno").on("click", function(e) {
            e.preventDefault();
            var last_keno_id = $(".js-keno_result-numbers .js-keno-item:last").attr("data-id");
            var _self = $(this);
            var _button_text = _self.html();
            _self.html("Đang tải...");
            $.ajax({
                url: base_url+'/api/get/kenos',
                data: {keno_id: last_keno_id },
                method: 'POST',
                type: 'json',
                success: function(res) {
                    _self.html(_button_text);
                }
            });
        });

        // readmore
        $('.js-btn-readmore').on('click', function(e){
            e.preventDefault();

            $('.js-bottom-content').removeClass('has-readmore');
        });

        $('.js-select-other-day').on('change', function(e){
            window.location.href = $(this).val();
        });
    },
    resetTableResult: function($ele) {
		$ele.find(".js-number").each(function(index, el) {
			if ($(el).attr("data-num")) {
				$(el).html("");
				$(el).html($(el).attr("data-num"));
			}
		});
	},
	showTableResult: function($ele, total_show_number) {
		total_show_number = total_show_number||2;
		$ele.find(".js-number").each(function(index, el) {
			if ($(el).hasClass(".js-loading-number")) return;
            var _number = $(el).attr("data-num");
			if (_number && _number.length >= total_show_number) {
				$(el).text(_number.slice(-total_show_number));
			}
		});
	},
	highlightTable: function($ele, number_hl, specify_page) {
		specify_page = specify_page || 0;
		$ele.find(".js-number").each(function(index, el) {
			if ($(el).hasClass(".js-loading-number")) return;
			var _number = $(el).text();
			if (specify_page != 0) {
				if ($(el).attr("data-page-id") != specify_page) return;
			}

			if (_number && _number.length > 2) {
				var _number_will_hl = _number.slice(-2);
				var _number_missing_hl = _number.slice(0, -2); //_number.replace(_number_will_hl, "");

				if (_number_will_hl.indexOf(number_hl) > -1) {
					// đầu tiên là thay = 2 kí tự cuối
					if (_number_will_hl.indexOf(number_hl) == 0) {
						$(el).html('<span class="highlight hangchuc">' + _number_will_hl + "</span>");
					} else {
						$(el).html('<span class="highlight donvi">' + _number_will_hl + "</span>");
					}

					$(el).prepend(_number_missing_hl);
				}
			} else {
				if (_number && _number.indexOf(number_hl) > -1) {
					if (_number.indexOf(number_hl) == 0) {
						$(el).html('<span class="highlight hangchuc">' + _number + "</span>");
					} else {
						$(el).html('<span class="highlight donvi">' + _number + "</span>");
					}
				}
			}
		});
	},
    renderDauDuoi:function(){
        var self = this;
        $('.js-config-view input[name="show_number"]').on('click', function() {
			var _self = $(this);
            var total_show_number = _self.val();
            // console.log(total_show_number);

			if (total_show_number == -1) {
				self.resetTableResult(_self.closest(".js-table-result"));
			} else {
				self.showTableResult(_self.closest(".js-table-result"), total_show_number);
			}

			var number_hl = _self.closest(".js-table-result").find(".js-hl-number.active").attr("data-highlight-number");

			if (typeof number_hl !== "undefined") {
				self.highlightTable(_self.closest(".js-table-result"), number_hl);
			}
		});

		$(".js-hl-number").hover(function() {
            var _self = $(this);
            var number_hl = _self.attr("data-highlight-number");

            self.resetTableResult(_self.closest(".js-table-result"));
            var total_show_number = _self.closest(".js-table-result").find('.js-config-view input[name="show_number"]:checked').val();
            if (total_show_number != -1) {
                self.showTableResult(_self.closest(".js-table-result"), total_show_number);
            }

            self.highlightTable(_self.closest(".js-table-result"), number_hl);
        },function() {
            var _self = $(this);
            if (!_self.hasClass("active")) {
                // nếu mà ko có cái click lúc nãy thì reset luôn
                if ($(".js-number-dau-duoi.active").length == 0) {
                    self.resetTableResult(_self.closest(".js-table-result"));
                } else {
                    // có thì trigger lại nó phát
                    $(".js-number-dau-duoi.active").trigger("click");
                }
            }
            $(".js-hl-number.active").trigger("click");
        });

		$(".js-hl-number").on('click', function() {
			var _self = $(this);
			var number_hl = _self.attr("data-highlight-number");

			// reset
			$(".js-hl-number").removeClass("active");
			self.resetTableResult(_self.closest(".js-table-result"));
			var total_show_number = _self.closest(".js-table-result").find('.js-config-view input[name="show_number"]:checked').val();
			if (total_show_number != -1) {
				self.showTableResult(_self.closest(".js-table-result"), total_show_number);
			}

			_self.addClass("active");
			self.highlightTable(_self.closest(".js-table-result"), number_hl);

			$(".js-number-dau-duoi").removeClass("active");
		});

		$(".js-table-kq").on('click', function() {
			self.resetTableResult($(this).closest(".js-table-result"));

			$(this).closest(".js-table-result").find("#show_number_all").prop("checked", true);
			$(this).closest(".js-table-result").find(".js-hl-number").removeClass("active");
			$(this).closest(".js-table-result").find(".js-number-dau-duoi").removeClass("active");
		});
        $(".js-number-dau-duoi").each(function(index, ele) {
            var _self = $(ele);
            var number_hl = _self.attr("data-num");
            var page_id = _self.attr("data-page-id");
            var date = new Date();
            var hour = date.getHours();
    
            var find_num_position = _self.attr("data-find") ? _self.attr("data-find") : "dau";
            var find_position = find_num_position == "duoi" ? 1 : 0;
    
            var js_table_result = _self.closest('.js-table-result');
    
            var find_number_matched = "";
            js_table_result.find(".js-number").each(function(index, el) {
                if ($(el).hasClass(".js-loading-number")) return;
        
                if ($(el).attr("data-page-id") == page_id) {
                    var _number = $(el).attr("data-num");
                    
                    if (_number && _number.length >= 2) {
                            
                        // lấy ra 2 kí tự
                        var _number_will_hl = _number.slice(-2);
                        // kiểm tra xem có số cần tìm ko
                        if (_number_will_hl.indexOf(number_hl) > -1) {
                            // nếu có số cần tìm thì số đó phải ở hàng chục
                            var _find_number_position = _number_will_hl.indexOf(number_hl);
                            if (find_num_position == "duoi") {
                                _find_number_position = 1 - _find_number_position;
                            }
            
                            if ( _number_will_hl.indexOf(number_hl) == find_position || _number_will_hl.slice(0, 1) == _number_will_hl.slice(-1)
                            ) {
                                var _number_will_hl_show = _number_will_hl.slice(-1);
                                if (find_position == 1) {
                                    _number_will_hl_show = _number_will_hl.slice(0, 1);
                                }
                                if (find_number_matched == "") {
                                    find_number_matched += 
                                        '<span class="number-dau-duoi js-highlight" data-highlight-number="' + 
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                } else {
                                    find_number_matched +=
                                        ', <span class="number-dau-duoi js-highlight" data-highlight-number="' +
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                }
                            }
                        }
                    }
                    
                }
            });
            // update vào html
            if (hour > 16 || find_number_matched != "" || js_table_result.find(".js-highlight").length > 0){
                _self.html(find_number_matched);
            }else{
                _self.html("_");
            }
        });

        // bind event cho cái hightligt mới luôn
		$(".js-table-result").on("click", ".js-number-dau-duoi", function() {
			var _self_highlight = $(this);
			var page_id = _self_highlight.attr("data-page-id");

			// reset
			$(".js-number-dau-duoi").removeClass("active");
			self.resetTableResult(_self_highlight.closest(".js-table-result"));

			_self_highlight.addClass("active");

			_self_highlight.find(".js-highlight").each(function(index, el) {
				var number_hl = $(el).attr("data-highlight-number");
				self.highlightTable(
					_self_highlight.closest(".js-table-result"),
					number_hl,
					page_id
				);
			});

			$(".js-hl-number").removeClass("active");
		});

        $(".js-kq-table").on('click', function() {
            self.resetTableResult($(this).closest(".js-table-result"));

            $(this).closest(".js-table-result").find("#show_number_all").prop("checked", true);
            $(this).closest(".js-table-result").find(".js-hl-number").removeClass("active");
            $(this).closest(".js-table-result").find(".js-number-dau-duoi").removeClass("active");
        });
    },
    renderDauCam: function(){
        if($('.js-thong-ke-loto').length == 0) return;

        var number_kep = {};
        var dau_cam_number = {};
        var duoi_cam_number = {};
        $(".js-number-dau-duoi").each(function(index, ele) {
            var _self = $(ele);
            var number_hl = _self.attr("data-num");
            var page_id = _self.attr("data-page-id");
            var date = new Date();
            var hour = date.getHours();
    
            var find_num_position = "dau";
            var find_position = find_num_position == "duoi" ? 1 : 0;
    
            var js_table_result = _self.closest('.js-table-result');
    
            var find_number_matched = "";
            js_table_result.find(".js-number").each(function(index, el) {
                if ($(el).hasClass(".js-loading-number")) return;
        
                if ($(el).attr("data-page-id") == page_id) {
                    var _number = $(el).attr("data-num");
                    
                    if (_number && _number.length >= 2) {
                            
                        // lấy ra 2 kí tự
                        var _number_will_hl = _number.slice(-2);
                        // kiểm tra xem có số cần tìm ko
                        if (_number_will_hl.indexOf(number_hl) > -1) {
                            // nếu có số cần tìm thì số đó phải ở hàng chục
                            var _find_number_position = _number_will_hl.indexOf(number_hl);
                            if (find_num_position == "duoi") {
                                _find_number_position = 1 - _find_number_position;
                            }
            
                            if ( _number_will_hl.indexOf(number_hl) == find_position || _number_will_hl.slice(0, 1) == _number_will_hl.slice(-1)) {
                                var _number_will_hl_show = _number_will_hl.slice(-1);
                                if (find_position == 1) {
                                    _number_will_hl_show = _number_will_hl.slice(0, 1);
                                }
                                if (find_number_matched == "") {
                                    find_number_matched += 
                                        '<span class="number-dau-duoi js-highlight" data-highlight-number="' + 
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                } else {
                                    find_number_matched +=
                                        ', <span class="number-dau-duoi js-highlight" data-highlight-number="' +
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                }
                                if(_number_will_hl.slice(0, 1) == _number_will_hl.slice(-1)){
                                    if(typeof number_kep[page_id] === 'undefined'){
                                        number_kep[page_id] = _number_will_hl;
                                    }else if(number_kep[page_id].indexOf(number_hl) === -1){
                                        number_kep[page_id] += ', '+_number_will_hl;
                                    }
                                }
                            }
                        }
                    }
                    
                }
            });
            // update vào html
            if (find_number_matched == ""){
                if(typeof dau_cam_number[page_id] === 'undefined'){
                    dau_cam_number[page_id] = number_hl+'x';
                    // dau_cam_number[page_id] = number_hl+'x';
                }else if(dau_cam_number[page_id].indexOf(number_hl) === -1){
                    dau_cam_number[page_id] += ', '+number_hl+'x';
                }
            }
        });

        $(".js-number-dau-duoi").each(function(index, ele) {
            var _self = $(ele);
            var number_hl = _self.attr("data-num");
            var page_id = _self.attr("data-page-id");
            var date = new Date();
            var hour = date.getHours();
    
            var find_num_position = "duoi";
            var find_position = find_num_position == "duoi" ? 1 : 0;
    
            var js_table_result = _self.closest('.js-table-result');
    
            var find_number_matched = "";
            js_table_result.find(".js-number").each(function(index, el) {
                if ($(el).hasClass(".js-loading-number")) return;
        
                if ($(el).attr("data-page-id") == page_id) {
                    var _number = $(el).attr("data-num");
                    
                    if (_number && _number.length >= 2) {
                            
                        // lấy ra 2 kí tự
                        var _number_will_hl = _number.slice(-2);
                        // kiểm tra xem có số cần tìm ko
                        if (_number_will_hl.indexOf(number_hl) > -1) {
                            // nếu có số cần tìm thì số đó phải ở hàng chục
                            var _find_number_position = _number_will_hl.indexOf(number_hl);
                            if (find_num_position == "duoi") {
                                _find_number_position = 1 - _find_number_position;
                            }
            
                            if ( _number_will_hl.indexOf(number_hl) == find_position || _number_will_hl.slice(0, 1) == _number_will_hl.slice(-1)) {
                                var _number_will_hl_show = _number_will_hl.slice(-1);
                                if (find_position == 1) {
                                    _number_will_hl_show = _number_will_hl.slice(0, 1);
                                }
                                if (find_number_matched == "") {
                                    find_number_matched += 
                                        '<span class="number-dau-duoi js-highlight" data-highlight-number="' + 
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                } else {
                                    find_number_matched +=
                                        ', <span class="number-dau-duoi js-highlight" data-highlight-number="' +
                                        _number_will_hl + '">' + _number_will_hl_show + "</span>";
                                }
                            }
                        }
                    }
                    
                }
            });
            // update vào html
            if (find_number_matched == ""){
                if(typeof duoi_cam_number[page_id] === 'undefined'){
                    duoi_cam_number[page_id] = 'x'+number_hl;
                    // dau_cam_number[page_id] = number_hl+'x';
                }else if(duoi_cam_number[page_id].indexOf(number_hl) === -1){
                    duoi_cam_number[page_id] += ', '+'x'+number_hl;
                }
            }
        });

        $('.js-thong-ke-loto-dau-cam').each(function(index, el){
            var page_id = $(el).attr('data-page-id');
            $(el).html(dau_cam_number[page_id]);
        });

        $('.js-thong-ke-loto-duoi-cam').each(function(index, el){
            var page_id = $(el).attr('data-page-id');
            $(el).html(duoi_cam_number[page_id]);
        });

        $('.js-thong-ke-loto-lo-kep').each(function(index, el){
            var page_id = $(el).attr('data-page-id');
            $(el).html(number_kep[page_id]);
        });
    },
    processSubmitForm: function(){
        var self = this;
        $('.js-form-submit-data').each(function(index, el){
            $(el).on('submit', function(event){
                event.preventDefault();
    
                if(self.is_requesting){
                    return;
                }

                self.is_requesting = true;
                var action_url = $(this).attr('data-action-url');
                if(!action_url) return;
                
                var _self = $(this);
                
                $.ajax({
                    url: action_url,
                    data: _self.serialize(),
                    type: 'POST',
                    dataType: 'json',
                    beforeSend: function(){
                        _self.find('.js-btn-hoan-tat').text('Đang xử lí...');
                    },
                    success: function(data){
                        _self.find('.js-btn-hoan-tat').text('Hoàn tất');
                        self.is_requesting = false;
                    },
                    error: function(data){
                        self.is_requesting = false;
                    }
                });
            });
        });

        $('.js-thong-ke-redirect-form select').on('change', function(){
            $(".js-form-submit-data").submit();
        });
    },
    quayThuKqxs: function() {
        var self = this;
    
        $(".js-btn-quay-thu-detail").on('click', function(event) {
            event.preventDefault();
            var _self = $(this);
            if (_self.hasClass("disabled")) return;
            $(this).addClass("disabled");
        
            if (_self.hasClass("da-quay-thu")) {
                $("html, body").animate({scrollTop: 0 }, "fast");
                window.location.reload();
            }
        
            $(this).html("Đang quay thử...");
            _self.addClass("da-quay-thu");
        
            $("html, body").animate({ scrollTop: _self.offset().top}, 1000);
        
            $(".js-table-quay-thu-live .js-number").each(function(k, el) {
                $(el).html('<div class="i_load"></div>');
                $(el).removeClass('next').addClass("js-loading js-loading-number");
                $(el).attr("data-num", "");
                $(el).removeAttr("has-animate");
            });

            var data_quay = _self.attr("data-quay");
            var is_tinh = "tinh" ? true : false;

            $(".js-table-quay-thu-live").removeClass("complete");
            self._quayThu(_self, is_tinh, 2000, 1000);
        });
    
        $('.js-change-province').on('change', function(event){
            $(this).closest('form').submit();
        });
    },
    generateNumber: function(speed) {
        speed = speed || 150;
        $(".js-loading-number.next").each(function(index, ele) {
            var el = $(ele);
            if (el.attr("has-animate") == 1) return;

            var animationTimer = setInterval(function() {
                
                if(typeof el.attr('data-num') !== 'undefined' && el.attr('data-num') != ''){
                    el.html(el.attr('data-num'));
                    clearInterval(animationTimer); // Stop the loop
                    return;
                }
                
                if (!el.hasClass("js-loading")) {
                    
                    clearInterval(animationTimer); // Stop the loop
                    return;
                } else {
                    var giai = el.attr("data-loai-giai");
                    var html = "";
            
                    if (giai == 9) {
                        html =
                        "" +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10);
                    }
                    if (giai == 8) {
                        html =
                        "" +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10);
                    }
            
                    if (giai == 7 || giai == 6) {
                        html =
                        "" +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10);
                    }
            
                    if (giai == 5 || giai == 4 || giai == 3 || giai == 2) {
                        html =
                        "" +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10);
                    }
            
                    if (giai == 1) {
                        html =
                        "" +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10) +
                        Math.floor(Math.random() * 10);
                    }
            
                    el.html(html);
                    el.attr("has-animate", 1);
                }
            }, speed);
        });
    },
    pad: function(n, width, z) {
        z = z || "0";
        n = n + "";
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    _quayThu: function($ele, tinh, time_out = 2000, time_between_province = 1500, button_text) {
        var self = this;
        if ($(".js-table-quay-thu-live").hasClass("complete")) {
        
            $ele.removeClass("disabled");
            $ele.html("Quay lại ngay!");
        
            $(".js-table-quay-thu-live .js-number").each(function(k, elx) {
                $(elx).attr("data-num", $(elx).html());
            });
        
            $(".js-table-quay-thu-live").find(".js-number").removeClass("next");
            clearTimeout(self.quaythutimeout);
            clearTimeout(self.number_quay_timout);
            clearTimeout(self.gen_number_quay_timout);
            self.renderDauDuoi();
            return;
        }
        
        var data_ketqua = $(".js-table-quay-thu-live").attr("data-page-id");
        var ketquas = data_ketqua.split(",");
        $(".js-table-quay-thu-live").find(".js-loading-number").removeClass("next");

        var timeout = time_out;
        if (!tinh) {
            
            for (var i = 0; i < ketquas.length; i++) {
                timeout += + i * time_between_province;
                $(".js-table-quay-thu-live .js-number.js-number-"+ketquas[i]).each(function(k, el) {
                    self.number_quay_timout = setTimeout(function() {
                        $(".js-number").removeClass("next");
                        if (isNaN($(el).html())) {
                            $(el).addClass("next");
                        }

                        self.generateNumber();
                        self.gen_number_quay_timout = setTimeout(function() {
                            $id_giai = $(el).attr("data-loai-giai");

                            var number = Math.floor(Math.random() * 99999) + 1;
                            $_number = self.pad(number, 5);
                            if ($id_giai == 9) {
                                number = Math.floor(Math.random() * 99) + 1;
                                $_number = self.pad(number, 2);
                            } else if ($id_giai == 8) {
                                number = Math.floor(Math.random() * 999) + 1;
                                $_number = self.pad(number, 3);
                            } else if ($id_giai == 7 || $id_giai == 6) {
                                number = Math.floor(Math.random() * 9999) + 1;
                                $_number = self.pad(number, 4);
                            } else if ($id_giai == 1) {
                                number = Math.floor(Math.random() * 999999) + 1;
                                $_number = self.pad(number, 6);
                            }

                            $(el).removeClass("js-loading-number js-loading");
                            $(el).attr('data-num', $(el).html());
                            if (isNaN($(el).html()) && $(el).attr("data-loai-giai") == $id_giai) {
                                $(el).html($_number);
                                $(el).attr("data-num", $_number);
                            }
                        }, 1000);
                    }, (k + 1) * timeout);
                });
            }
        } else {
            timeout = time_out;
            $(".js-table-quay-thu-live .js-number").each(function(k, xel) {
                self.number_quay_timout = setTimeout(function() {
                    $(".js-number").removeClass("next");
                    if (isNaN($(xel).html())) {
                        $(xel).addClass("next");
                    }

                    self.generateNumber();
                    self.gen_number_quay_timout = setTimeout(function() {
                        $id_giai = $(xel).attr("data-loai-giai");

                        var number = Math.floor(Math.random() * 99999) + 1;
                        $_number = self.pad(number, 5);
                        if ($id_giai == 9) {
                            number = Math.floor(Math.random() * 99) + 1;
                            $_number = self.pad(number, 2);
                        } else if ($id_giai == 8) {
                            number = Math.floor(Math.random() * 999) + 1;
                            $_number = self.pad(number, 3);
                        } else if ($id_giai == 7 || $id_giai == 6) {
                            number = Math.floor(Math.random() * 9999) + 1;
                            $_number = self.pad(number, 4);
                        } else if ($id_giai == 1) {
                            number = Math.floor(Math.random() * 999999) + 1;
                            $_number = self.pad(number, 6);
                        }

                        $(xel).removeClass("js-loading-number js-loading");
                        $(xel).attr('data-num', $(xel).html());
                        if (isNaN($(xel).html()) && $(xel).attr("data-loai-giai") == $id_giai) {
                            $(xel).html($_number);
                            $(xel).attr("data-num", $_number);
                        }
                    }, 1000);
                }, (k + 1) * timeout);
            });
        }
        
        // if ($(".js-table-quay-thu-live").find(".js-loading-number").length == 0) {
        //     $(".js-table-quay-thu-live").addClass("complete");
        //     $ele.html("Đang thống kê đầu đuôi...");
        // }
    
        // self.quaythutimeout = setTimeout(function() {
        //     self._quayThu($ele, tinh);
        // }, 3 * 1000);
        self.checkTableQuayThu($ele, button_text);
    },
    checkTableQuayThu:function($ele, button_text){
        var self = this;
        self.renderDauDuoi();

        if ($(".js-table-quay-thu-live").find(".js-loading-number").length == 0) {  
            clearTimeout(self.quaythutimeout);
            clearTimeout(self.number_quay_timout);
            clearTimeout(self.gen_number_quay_timout);

            $(".js-table-quay-thu-live").addClass("complete");
            $ele.html("Đang thống kê đầu đuôi...");
            $ele.removeClass("disabled");
            $ele.html(button_text);

            $(".js-table-quay-thu-live .js-number").each(function(k, elx) {
                $(elx).attr("data-num", $(elx).html());
            });
            
            $(".js-table-quay-thu-live").find(".js-number").removeClass("next");
            clearTimeout(self.quaythutimeout);
            return;
        }

        self.quaythutimeout = setTimeout(function() {
            // _quayThu($ele, tinh);
            self.checkTableQuayThu($ele, button_text);
        }, 1000);
    },
    rating: function () {
		var old_result_lable = $(".js-rating-social-wrap .js-rating-label-result").html();
        
		$(".js-rating-social-wrap .js-rating").on("click", function (event) {
			var _self = $(this);

			old_result_lable = $(".js-rating-social-wrap label[for='" + _self.attr("id") + "']").attr("title");
            
            $(".js-rating-social-wrap .js-rating-label-result").html(old_result_lable);
            
			var object_id = _self.attr("data-object-id");
			var rating = _self.val();
			var object_type = _self.attr("data-object-type");
			if (!object_id) return;

			$.ajax({
				url: base_api_url + "utility/rating",
				data: {
					object_id: object_id,
					object_type: object_type,
					rating: rating
				},
				type: "POST",
				dataType: "json"
			});
		});

		$(".js-rating-social-wrap .js-rating-label").hover(
			function () {
				$(".js-rating-social-wrap .js-rating-label-result").html(
					$(this).attr("title")
				);
			},
			function () {
				$(".js-rating-social-wrap .js-rating-label-result").html(
					old_result_lable
				);
			}
		);
	},
    xoSoTinh: function(){
        var self = this;
        var date = new Date();

		var hour = date.getHours();
		var minutes = date.getMinutes();

        if (hour >= 16) {
            $('.js-box-result-tinh-live').removeClass('hidden');
            if(minutes>=10){
                $(".js-box-result-tinh-live").each(function(index, el) {
                    var _self = $(el);
                    if (_self.hasClass("complete")) {
                        self.renderDauDuoi();
                        return;
                    }
                    
                    var id_ketqua = $(el).attr("data-ketqua-id");
                    _self.find(".js-loading-number").removeClass("next");
                    if (_self.find(".js-loading-number").length == 0) {
                        _self.addClass("complete");
                    }
                    self._liveXoSo(id_ketqua, _self.attr('data-mien'));
                });
            }
        }

        setTimeout(function() {
			self.xoSoTinh();
		}, 10 * 1000);
    },
    xosoMien: function(){
        var self = this;
        var date = new Date();

		var hour = date.getHours();
		var minutes = date.getMinutes();
		
        $(".js-live-message").addClass("hidden");
        if (hour == 16){
            $(".js-box-result-mien-nam-live").removeClass('hidden');

            if (!$(".js-live-message-mien-nam").hasClass("done")) {
                $(".js-live-message-mien-nam").removeClass("hidden");
            }
        }
        if (hour == 17){
            $(".js-box-result-mien-trung-live").removeClass('hidden');

            if (!$(".js-live-message-mien-trung").hasClass("done")) {
                $(".js-live-message-mien-trung").removeClass("hidden");
            }
        }
        if (hour == 18){
            $(".js-box-result-mien-bac-live").removeClass('hidden');

            if (!$(".js-live-message-mien-bac").hasClass("done")) {
                $(".js-live-message-mien-bac").removeClass("hidden");
            }
        }

        
        if (hour >= 16 && minutes>=10) {
            $(".js-box-result-mien-nam-live").each(function(index, el) {
                
                var _self = $(el);
                if (_self.hasClass("complete")) {
                    self.renderDauDuoi();
                    return;
                }
            
                _self.find(".js-loading-number").removeClass("next");
                if (_self.find(".js-loading-number").length == 0) {
                    _self.addClass("complete");
                }

                var id_ketquas = $(el).attr("data-ketqua-id").split(",");
                var mien = _self.attr('data-mien');
                
                $.each(id_ketquas, function(i, id_ketqua){
                    self._liveXoSo(id_ketqua, mien);
                });

                console.log(_self.find(".js-loading-number").length);
                if (_self.find(".js-loading-number").length == 0) {
                    _self.find('.alert').remove();
                    _self.addClass("complete");
                    $(".js-live-message").addClass("hidden");
                    $(".js-live-message-mien-nam").removeClass("hidden");
                }
            });
        }
        if (hour >= 17 && minutes>=10) {
            $(".js-box-result-mien-trung-live").each(function(index, el) {
                
                var _self = $(el);
                if (_self.hasClass("complete")) {
                    self.renderDauDuoi();
                    return;
                }
            
                _self.find(".js-loading-number").removeClass("next");
                if (_self.find(".js-loading-number").length == 0) {
                    _self.addClass("complete");
                }

                var id_ketquas = $(el).attr("data-ketqua-id").split(",");
                var mien = _self.attr('data-mien');
                
                $.each(id_ketquas, function(i, id_ketqua){
                    self._liveXoSo(id_ketqua, mien);
                });
            });
        }
        if (hour >= 18 && minutes>=10) {
            $(".js-box-result-mien-bac-live").each(function(index, el) {
                
                var _self = $(el);
                if (_self.hasClass("complete")) {
                    self.renderDauDuoi();
                    return;
                }
            
                _self.find(".js-loading-number").removeClass("next");
                if (_self.find(".js-loading-number").length == 0) {
                    _self.addClass("complete");
                }

                var id_ketqua = $(el).attr("data-ketqua-id");
                var mien = _self.attr('data-mien');
                self._liveXoSo(id_ketqua, mien);
            });
        }

        setTimeout(function() {
			self.xosoMien();
		}, 10 * 1000);
    },
    _liveXoSo: function(id_ketqua, mien){
        var self = this;
        var date = new Date();
        var hour = date.getHours();
        var minutes = date.getMinutes();
        var url_quay = base_url+"/jsons/";
        
        $.getJSON(url_quay + id_ketqua + ".json?t=" + date.getTime(), function(data) {
            
            if(mien == 11){
                data.sort(function(a, b){
                    if(a.id_loaigiai == 1 || b.id_loaigiai == 1){
                        return -1;
                    }else if(a.id_loaigiai > b.id_loaigiai){
                        return 1;
                    }else if(a.id_loaigiai < b.id_loaigiai){
                        return -1;
                    }else if(a.id_loaigiai == b.id_loaigiai){
                        return a.step > b.step;
                    }
                });
            }else{
                data.sort(function(a, b){
                    if(a.id_loaigiai < b.id_loaigiai){
                        return 1;
                    }
                    if(a.id_loaigiai > b.id_loaigiai){
                        return -1;
                    }
                    if(a.id_loaigiai == b.id_loaigiai){
                        return a.step > b.step;
                    }
                });
            }
            
            // var has_class_next = false;
            // has_class_next[k] = false;
            self.has_class_next[id_ketqua] = false;
            
            $.each(data, function(k, v) {
                if (!self.has_class_next[id_ketqua] && !v.number && $('.number_'+id_ketqua+'.js-loading-number.next').length ==0){
                    // console.log("#number_" + v.id);
                    $('.number_'+id_ketqua+'.js-loading-number').removeClass("next");
                    $("#number_" + v.id).addClass("next");
                    self.has_class_next[id_ketqua] = true;
                    self.generateNumber();
                }
                
                if(v.number != ''){
                    $("#number_" + v.id).removeClass("js-loading")
                    .html(v.number)
                    .attr("data-num", v.number)
                    .removeClass("js-loading-number");
                        
                    self.has_class_next[id_ketqua] = false;
                }
                
            });
        });
    
    },
    xosoVietlott: function(){
        var date = new Date();
        var hour = date.getHours();
        var minutes = date.getMinutes();
        var day = date.getDay();
        var self = this;
    
        if(hour >= 16 && hour < 18){
            $('.js-result-number-power').each(function(index, el){
                $(el).closest('.js-result-power-wrap').find('.js_result_comingsoon').removeClass('hidden');				
            });

            $('.js-max4d-live').removeClass('hidden');
            $('.js-mega645-live').removeClass('hidden');
            $('.js-power655-live').removeClass('hidden');
            $('.js-max3d-live').removeClass('hidden');
        }
    
        if(hour >= 18  && (day == 2 || day == 4 || day == 6)){
            $('.js-max4d-live').removeClass('hidden');
            $('.js-max4d-live').each(function(index, el){
                
                var jEl = $(el);
                if(jEl.hasClass('complete')) return;

                $('.js-max4d-live').find('.js-loading-number').addClass('next');
                self.generateNumber(200);
                //$(el).closest('.js-result-power-wrap').find('.js_result_comingsoon').addClass('hidden');
                if(jEl.hasClass('complete')) {

                    jEl.find('.js-notify-6gio').hide();
                    return;
                }
    
                var max4d_id = $(el).attr('data-max4d');
                //if(typeof max4d_id === 'undefined') return;
                $.getJSON(base_url+'/max4d_jsons/'+max4d_id+'.json?t='+date.getTime(), function(data) {
                    $.each( data, function(k,v) {
                        if(k == 'giai_nhat' && v.length > 0){

                            $('#MAX4D_NUM_G1').html(v);
                            $('#MAX4D_NUM_G1').removeClass('next js-loading');

                            $('#MAX4D_NUM_KK1').html('*'+parseInt(v%1000));
                            $('#MAX4D_NUM_KK1').removeClass('next js-loading');
                            
                            $('#MAX4D_NUM_KK2').html('**'+parseInt(v%100));
                            $('#MAX4D_NUM_KK2').removeClass('next js-loading');
                            
                        }
    
                        if(k == 'giai_nhi_1' && v.length > 0){
                            $('#MAX4D_NUM_G2_1').html(v);
                            $('#MAX4D_NUM_G2_1').removeClass('next js-loading');
                        }
                        if(k == 'giai_nhi_2' && v.length > 0){
                            $('#MAX4D_NUM_G2_2').html(v);
                            $('#MAX4D_NUM_G2_2').removeClass('next js-loading');
                        }
                        
                        if(k == 'giai_ba_1' && v.length > 0){
                            $('#MAX4D_NUM_G3_1').html(v);
                            $('#MAX4D_NUM_G3_1').removeClass('next js-loading');
                        }
    
                        if(k == 'giai_ba_2' && v.length > 0){
                            $('#MAX4D_NUM_G3_2').html(v);
                            $('#MAX4D_NUM_G3_2').removeClass('next js-loading');
                        }
                        
                        if(k == 'giai_ba_3' && v.length > 0){
                            $('#MAX4D_NUM_G3_3').html(v);
                            $('#MAX4D_NUM_G3_3').removeClass('next js-loading');
                        }
    
                        if(k == 'soluong_1' && v != ''){
                            $('#MAX4D_SL_1').html(v);
                        }
                        if(k == 'soluong_2' && v != ''){
                            $('#MAX4D_SL_2').html(v);
                        }
                        if(k == 'soluong_3' && v != ''){
                            $('#MAX4D_SL_3').html(v);
                        }
                        if(k == 'soluong_4' && v != ''){
                            $('#MAX4D_SL_4').html(v);
                        }
                        if(k == 'soluong_5' && v != ''){
                            $('#MAX4D_SL_5').html(v);
                            jEl.addClass('complete');
                        }
                    });
                });	
            });
            
            $('.js-power655-live').show();
            $('.js-power655-live').each(function(index, el){
                // mặc định thì ẩn 
                var jEl = $(el);
                jEl.find('.js-notify-4gio').hide();
                jEl.find('.js-notify-6gio').show();
                $('.js-power655-live').find('.js-loading-number').addClass('next');
                self.generateNumber(200);
                //$(el).closest('.js-result-power-wrap').find('.js_result_comingsoon').addClass('hidden');
                if(jEl.hasClass('complete') || jEl.hasClass('has-number')) {

                    jEl.find('.js-notify-6gio').hide();
                    return;
                }
    
                var power_id = $(el).attr('data-power');
                if(typeof power_id === 'undefined') return;
                
                $.getJSON(base_url+'/power_jsons/power_'+power_id+'.json?t='+date.getTime(), function(data) {
                    $.each( data, function(k,v) {
                        //$('#mad_db_'+id_ketqua).html(data.ma_1ty);
                        
                        var numbers = data.number;
                        numbers.forEach(function(number, index){
                            
                            var _number = number.num != '' ? number.num : '?';

                            if(_number != '?' && _number > 0){
                                if(_number<10){
                                    _number = '0'+ parseInt(_number);
                                }
                                $('#POWER655_NUM_'+(index+1)).html(_number);
                                $('#POWER655_NUM_'+(index+1)).removeClass('loading');
                                $('#POWER655_NUM_'+(index+1)).removeClass('next');
                                if(index == 6){
                                    jEl.addClass('has-number');
                                    jEl.find('.js-notify-6gio').hide();
                                }
                            }
                            
                        });
    
                        var giaithuongs = data.giaithuong;
                        var jackpot_1 = '';
                        var jackpot_2 = '';
                        giaithuongs.forEach(function(giai_thuong, index){
                            
                            var _soluong = giai_thuong.so_luong != 'Đang cập nhật...' ? giai_thuong.so_luong : '<div class="i_load"></div>';
                            
                            if(index == 0){
                                jackpot_1 = giai_thuong.gia_tri;
                                $('#POWER655_GIATRI_JACK1_MAIN').html(jackpot_1);
                                $('#POWER655_GIATRI_JACK1').html(jackpot_1);
                                $('#POWER655_GIATRI_JACK1_SHORT').html(giai_thuong.gia_tri_short);
                                $('#POWER655_SL_JACK1').html(_soluong);
                                $('#POWER655_SL_JACK1').removeClass('loading');
                            }
                            if(index == 1){
                                jackpot_2 = giai_thuong.gia_tri;
                                $('#POWER655_GIATRI_JACK2_MAIN').html(jackpot_2);
                                $('#POWER655_GIATRI_JACK2').html(jackpot_2);
                                $('#POWER655_GIATRI_JACK2_SHORT').html(giai_thuong.gia_tri_short);
                                $('#POWER655_SL_JACK2').html(_soluong);
                                $('#POWER655_SL_JACK2').removeClass('loading');
                            }
                            if(index == 2){
                                $('#POWER655_SL_G1').html(_soluong);
                                $('#POWER655_SL_G1').removeClass('loading');
                            }
                            if(index == 3){
                                $('#POWER655_SL_G2').html(_soluong);
                                $('#POWER655_SL_G2').removeClass('loading');
                            }
                            if(index == 4){
                                $('#POWER655_SL_G3').html(_soluong);
                                $('#POWER655_SL_G3').removeClass('loading');
                                if(giai_thuong.so_luong != 'Đang cập nhật...'){
                                    jEl.addClass('complete');
                                }
                            }
                        });
                    }); 
                });
            });
    
            setTimeout(function(){
                self.xosoVietlott();
            }, 10 * 1000);
        } 
        
        //testing
        if(hour >= 18  && (day == 0 || day == 3 || day == 5)){
            $('.js-mega645-live').show();
            $('.js-mega645-live').each(function(index, el){

                var jEl = $(el);
                if(jEl.hasClass('complete')) return;

                $('.js-mega645-live').find('.js-loading-number').addClass('next');
                self.generateNumber(150);
    
                var mega_id = jEl.attr('data-mega');
                
                $.getJSON(base_url+'/mega_jsons/'+mega_id+'.json?t='+date.getTime(), function(data) {
                    $.each( data, function(k,v) {
    
                        var numbers = data.number;
                        var html_number = '';
                        numbers.forEach(function(number, index){
                            var _number = number.num != '' ? number.num : '?';
                            
                            if(_number != '?' && _number > 0){
                                
                                if(_number<10){
                                    _number = '0'+ parseInt(_number);
                                }
                                $('#MEGA645_NUM_'+(index+1)).html(_number);
                                $('#MEGA645_NUM_'+(index+1)).removeClass('next js-loading js-loading-number');
                            }
                        });

                        var giaithuongs = data.giaithuongs;
                        if(giaithuongs){
                            giaithuongs.forEach(function(giai_thuong, index){
                                
                                var _soluong = giai_thuong.so_luong != '' && giai_thuong.so_luong != 'Đang cập nhật...' ? giai_thuong.so_luong : '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>';
                                
                                if(index == 0){
                                    var jackpot = giai_thuong.gia_tri;
                                    $('#MEGA645_GIATRI_JACK').html(jackpot);
                                    $('#MEGA645_GIATRI_JACK_MAIN').html(jackpot);
                                    $('#MEGA645_SL_JACK1_SHORT').html(giai_thuong.gia_tri_short);
                                    $('#MEGA645_SL_JACK').html(_soluong);
                                    $('#MEGA645_SL_JACK1').removeClass('loading');
                                    $('#JACKPOT_CHANGE').html(giai_thuong.chenh_lech);
                                }else{
                                    $('#MEGA645_SL_G'+index).html(_soluong);
                                    $('#MEGA645_SL_G'+index).removeClass('loading');
                                    if(index == 3){
                                        if(_soluong!=''){
                                            jEl.addClass('complete');
                                        }
                                    }
                                }
    
                            });
                        }
                        
                    });
                });
            });
    
            setTimeout(function(){
                self.xosoVietlott();
            }, 10 * 1000);
    
        }

        if (hour >= 18 && (day == 1 || day == 3 || day == 5)) {
            $('.js-max3d-live').removeClass('hidden');
			$(".js-max3d-live").each(function(index, el) {
                
                var jEl = $(el);
                if (jEl.hasClass("complete")) return;
                // $('.js-max3d-live').find('.js-loading-number').addClass('next');
                // self.generateNumber(200);
                //$(el).closest('.js-result-power-wrap').find('.js_result_comingsoon').addClass('hidden');
		
				var max3d_id = jEl.attr("data-max3d");
				//if(typeof max4d_id === 'undefined') return;
				var number_giai_nhat = "";
				$.getJSON(base_url+"/max3d_jsons/" + max3d_id + ".json?t=" + date.getTime(),
					function(data) {
						$.each(data, function(k, v) {
							if(v.length>0){
								$(el).find('#'+k).html(v);
                            }
                            
                            if(k == 'SL_MAX3D3D_7' && v.length >0){
                                jEl.addClass('complete');
                            }
						});
					}
				);
            });
            
            setTimeout(function(){
                self.xosoVietlott();
            }, 10 * 1000);
        }
        
        if (hour >= 6 && hour <= 21) {
            if ($(".js-keno-result-box").length) {
                var latest_keno_item = $(
                    ".js-keno_result-numbers .js-keno-item.done:first"
                );
                var next_next_time = latest_keno_item.find('.keno_time');
                var next_next_date = latest_keno_item.find('.keno_date');
                var keno_today = date.getFullYear() + "-" + self.pad(date.getMonth() + 1, 2) + "-" + self.pad(date.getDate(), 2);
                var current_ky_quay = parseInt(latest_keno_item.attr('data-kyquay')) + 1;
                var next_ky_quay = next_ky_quay || current_ky_quay;
                var keno_reload_next = $('.js-keno-item-next').clone();
                var next_minute =
                    minutes % 10 > 1 ?
                    parseInt(minutes / 10) * 10 + 10 :
                    parseInt(minutes / 10) * 10;
                var next_hour = hour;
                if (next_minute > 51) {
                    next_minute = "00";
                    next_hour = hour + 1;
                }
                
                if ($('.js-keno-item-next').find('.keno_time').html() == latest_keno_item.find('.keno_time').html()) {
                    // self.keno_current_done = true;
                    self.keno_next = false;
                } else {
                    self.keno_next = true;
                    // self.keno_current_done = false;
                }

                // if(minutes % 10 == 0 || minutes % 10 > 1 ){
                if (self.keno_current_done) {
                    $('.js-keno_result-numbers')
                        .find(".js-time-result")
                        .countdown(
                            date.getFullYear() +"/" +self.pad(date.getMonth() + 1, 2) +"/" +self.pad(date.getDate(), 2) +" " +self.pad(next_hour, 2) +":" +self.pad(next_minute + 1, 2) +":00"
                        )
                        .on("update.countdown", function(event) {
                            var format = "%M:%S";
                            $(this).html(event.strftime(format));
                        });

                    
                    $('.js-keno-item-next').addClass("hidden");
                    $('.js-keno-item-next').find(".keno-num").removeClass("js-loading").removeAttr("has-animated");

                    $('.js-time-notify').find('.js-time-result').countdown(
                        date.getFullYear() +"/" +self.pad(date.getMonth() + 1, 2) +"/" +self.pad(date.getDate(), 2) +" " +self.pad(next_hour, 2) +":" +self.pad(next_minute + 1, 2) +":00"
                    )
                    .on("update.countdown", function(event) {
                        var format = "%M:%S";
                        $(this).html(event.strftime(format));
                    });

                    $('.js-time-notify').find('.js-next-ky-quay').html('#'+next_ky_quay);
                    $('.js-time-notify').removeClass('hidden');

                }

                if (minutes % 10 >= 1 && minutes % 10 <= 2 && self.keno_next && !self.keno_current_done) {
                // if (self.keno_next && !self.keno_current_done) {
                    $('.js-keno-item-next').removeClass("hidden");
                    $('.js-keno-item-next').find(".keno-num").addClass("js-loading next");
                    $('.js-keno-item-next').find(".js-time-result").addClass("hidden");
                    self.generateNumber(150);
                    
                    $('.js-time-notify').addClass('hidden');
                }else{
                    $('.js-keno-item-next').addClass("hidden");
                    $('.js-keno-item-next').find(".keno-num").removeClass("js-loading").removeAttr("has-animated");

                    $('.js-time-notify').find('.js-time-result').countdown(
                        date.getFullYear() +"/" +self.pad(date.getMonth() + 1, 2) +"/" +self.pad(date.getDate(), 2) +" " +self.pad(next_hour, 2) +":" +self.pad(next_minute + 1, 2) +":00"
                    )
                    .on("update.countdown", function(event) {
                        var format = "%M:%S";
                        $(this).html(event.strftime(format));
                    });

                    $('.js-time-notify').find('.js-next-ky-quay').html('#'+next_ky_quay);
                    $('.js-time-notify').removeClass('hidden');
                }


                if (!isNaN(next_ky_quay)) {
                    $('.js-keno-item-next').find(".js-ky-tiep-theo").html("Kỳ #" + next_ky_quay);
                    $('.js-keno-item-next').attr('data-kyquay', next_ky_quay);
                    $('.js-keno-item-next').find('.keno_time').html(self.pad(next_hour, 2) +":" +self.pad(next_minute, 2));
                    $('.js-keno-item-next').find('.keno_date').html(self.pad(date.getDate(), 2) +"/" +self.pad(date.getMonth() + 1, 2) +"/" + date.getFullYear());
                }

                $.getJSON(base_url+"/keno_jsons/" + keno_today + ".json?t=" + date.getTime(),
                    function(data) {
                        var chan = le = lon = nho = 0;
                        $.each(data, function(k, v) {
                            // console.log('current_ky_quay', current_ky_quay);
                            if (k == current_ky_quay && !self.keno_current_done) {
                                var _html_number = "";
                                $.each(v.numbers, function(_i, _v) {
                                    if (!isNaN(_v.number)) {
                                        if(_v.number%2==0){
                                            chan++
                                        }else{
                                            le++;
                                        }
                                        if(_v.number > 40){
                                            lon++;
                                        }else{
                                            nho++;
                                        }
                                        _html_number +=
                                            '<span class="keno-num">' + self.pad(_v.number, 2) + "</span>";
                                    } else {
                                        _html_number +=
                                            '<span class="keno-num number"><div class="i_load"></div></span>';
                                    }
                                });
                                // notification_sound.play();
                                $('.js-keno-item-next').find('.keno_numbers').html(_html_number);
                                $('.js-keno-item-next').find('.js-chan-le').html(chan+'/'+le);
                                $('.js-keno-item-next').find('.js-lon-nho').html(lon+'/'+nho);

                                if (v.done) {
                                    next_minute = parseInt(minutes / 10) * 10 + 10;
                                    next_hour = hour;
                                    if (next_minute > 50) {
                                        next_minute = "00";
                                        next_hour = hour + 1;
                                    }
                                    next_next_date = self.pad(date.getDate(), 2) + "/" + self.pad(date.getMonth() + 1, 2) +"/" + date.getFullYear();
                                    next_next_time = self.pad(next_hour, 2) +":" +self.pad(next_minute, 2);
                                    next_ky_quay = current_ky_quay + 1;
                                    

                                    $('.js-keno-item-next').find('.keno_time').html(v.time);
                                    $('.js-keno-item-next').find('.keno_date').html(v.date);
                                    $('.js-keno-item-next').removeClass("keno-item-live js-keno-item-next hidden").addClass("js-keno-item done");
                                    $('.js-latest-ky-quay').html(current_ky_quay);

                                    keno_reload_next.prependTo('.js-keno_result-numbers');
                                    keno_reload_next.find('.keno_time').html(next_next_time);
                                    keno_reload_next.find('.keno_date').html(next_next_date);
                                    keno_reload_next.find(".js-ky-tiep-theo").html("Kỳ #" + next_ky_quay);
                                    keno_reload_next.attr('data-kyquay', next_ky_quay);
                                    keno_reload_next.find('.js-chan-le').html('0/0');
                                    keno_reload_next.find('.js-lon-nho').html('0/0');
                                    keno_reload_next.addClass("js-keno-item hidden");
                                    keno_reload_next.removeClass("keno-next-live");
                                    
                                    $('.js-time-notify').find(".js-time-result").removeClass("hidden");
                                    // reload thong ke
                                    // getThongkeKeno();
                                    self.keno_current_done = true;
                                }
                            }
                        });
                    }
                );
            }
        }
    
        if(hour < 18){
            setTimeout(function(){
                self.xosoVietlott();
            }, 10 * 1000);
        }
    },
    sound: function(){
        var self = this;
        soundManager.setup({
            // where to find the SWF files, if needed
            url: "soundmanager/swf/",
    
            onready: function() {
                // SM2 has loaded, API ready to use e.g., createSound() etc.
                self.notification_sound = soundManager.createSound({
                    url: base_url + "/sound/sound.wav"
                });
            },
    
            ontimeout: function() {
                // Uh-oh. No HTML5 support, SWF missing, Flash blocked or other issue
            }
        });
    },
    addZeros: function(value, len) {
        value = '' + value;
        if (value.length < len) {
          for (var i = 0; i < len - value.length; i++) {
            value = '0' + value;
          }
        }
        return value;
    },
    guestNgaySinh: function(){
        var self = this;
        $('.js-btn-xem-ngay-sinh').on('click', function(e){
            e.preventDefault();
            var _self = $(this);
            var _button_text = _self.html();
            _self.html("Đang xử lý...");

            $.ajax({
                url: base_api_url+'get/suggest/number',
                data: {
                    day: $('.js-select-ngay-sinh').val(),
                    month: $('.js-select-thang-sinh').val(),
                    year: $('.js-select-nam-sinh').val(),
                },
                method: "post",
                type: "json",
                success: function(res) {
                    _self.html(_button_text);
                    if (!res.error) {
                        var date = new Date();

                        var day = date.getDate();
                        var month = date.getMonth();
                        var _month = month + 1;
                        var year = date.getFullYear();

                        $('.js-show-ngay').html(self.addZeros(day, 2)+'/'+self.addZeros(_month,2)+'/'+year);
                        $('.js-show-cung-hd').html(res.data.cung_hd);
                        $('.js-show-sao').html(res.data.cung_menh);
                        $('.js-show-mau-sac').html(res.data.mau_menh);
                        $('.js-show-btl').html(res.data.btl);
                        $('.js-show-stl').html(res.data.stl_1);
                        $('.js-show-stl2').html(res.data.stl_2);
                        $('.js-show-db').html(res.data.db);
                        $('.js-show-lot').html(res.data.lot);
                        var b

                        $('.js-kq-xem-ngay-sinh').removeClass('hidden');
                        _self.addClass('hidden');
                        $('.js-btn-xem-lai-ngay-sinh').removeClass('hidden');
                        $('.js-error').addClass('hidden');
                    }else{
                        $('.js-error').removeClass('hidden');
                    }
                }
            });
        });

        $('.js-btn-xem-lai-ngay-sinh').on('click', function(e){
            e.preventDefault();

            $('.js-btn-xem-ngay-sinh').removeClass('hidden');
            $('.js-kq-xem-ngay-sinh').addClass('hidden');
            $(this).addClass('hidden');
        });
    },
    loadMorePostItem: function(){
        var self = this;
        $(".js-load-readmore-post-item").on("click", function(event) {
            event.preventDefault();
            var _self = $(this);

            var last_id = _self.attr('data-last-id');
            var mien = _self.attr('data-mien') || 0;
            var type = _self.attr('data-type');
            var province_id = _self.attr('data-province-id') || 0;
            
            var _button_text = _self.html();
            _self.html('<span>Đang tải...</span>');

            $.ajax({
                url: base_api_url+'get/post/more',
                data: {
                    mien: mien,
                    province_id: province_id,
                    type: type,
                    last_id: last_id
                },
                method: "post",
                type: "json",
                success: function(res) {
                    if (res.success && res.html) {
                        $(".js-list-articles").append(res.html);
                        _self.attr('data-last-id', res.last_id);
                        common_app.lazyLoadImage();
                    }
                    _self.html(_button_text);
                    if(res.html == ''){
                        _self.remove();
                    }
                }
            });
        });
    },
    loadMoreKqxs: function(){
        var self = this;
        $(".js-load-readmore-kqxs-item").on("click", function(event) {
            event.preventDefault();
            var _self = $(this);

            var last_date = _self.attr('data-last-date');
            var mien = _self.attr('data-mien');
            var province_id = _self.attr('data-province-id');
            var week_day = _self.attr('data-weekday') || '';
            var _button_text = _self.html();
            _self.html('<span>Đang tải...</span>');
            var url = base_api_url+'get/xoso/mien/more';
            if(province_id > 0){
                url = base_api_url+'get/xoso/tinh/more';
            }
            if(week_day != ''){
                url = base_api_url+'get/xoso/mien/weekday/more';
            }

            $.ajax({
                url: url,
                data: {
                    mien: mien,
                    province_id: province_id,
                    last_date: last_date,
                    week_day: week_day
                },
                method: "post",
                type: "json",
                success: function(res) {
                    if (res.success && res.html) {
                        $(".js-wrap-kqxs-inner").append(res.html);
                        _self.attr('data-last-date', res.last_date);
                        self.renderDauDuoi();
                    }
                    _self.html(_button_text);
                }
            });
        });
    },
	init: function () {
        this.layoutFront();
        this.renderDauDuoi();
        this.renderDauCam();
        this.processSubmitForm();
        this.quayThuKqxs();
        this.rating();
        this.xoSoTinh();
        this.xosoMien();
        this.xosoVietlott();
        this.guestNgaySinh();
        this.loadMorePostItem();
        this.loadMoreKqxs();
	}
};

$(document).ready(function(){
	var jBody = $(document.body);

	if (jBody.hasClass("js-loaded")) return;
	jBody.addClass("js-loaded");
    front_app.init();

    $(document).ajaxComplete(function (event, xhr, settings) {
		// reinit facebook
		$('.preload').remove();
		$('#loading').addClass('hidden');
		window.show_loading = false;
		if (!xhr.responseText) return;
        var response = $.parseJSON(xhr.responseText);

		if (response.data_code != 200) {
			if (response.data_code == 500) {
                if(!response.message) return;
				swal({
					title: response.title,
					html: response.message,
					type: 'error',
					timer: 3000,
					showConfirmButton: false
				}).catch(swal.noop);
				return;
			}
		}
		if (typeof response.action == 'undefined' || response.action == '') return;
		var timeout;
		$.each(response.action.trim().split(' '), function (index, action) {

			if (action == 'redirect') {
				timeout = response.action.trim().split(' ').length > 1 ? 3000 : 500;
				setTimeout(function () {
					window.location.href = response.link;
				}, timeout);
            }
			if (action == 'redirect_suddenly') {
				window.location.href = response.link;
            }

			if (action == 'reload') {
				timeout = response.action.trim().split(' ').length > 1 ? 3000 : 500;
				setTimeout(function () {
					window.location.reload();
				}, timeout);
			}

			if (action == 'alert') {
				toastr.options = {
					"closeButton": true,
					"showDuration": "300",
					"hideDuration": "3000",
					"timeOut": "5000"
				};
				toastr.info(response.message, 'Thông báo');
			}

			if (action == 'sweetalert') {
				swal({
					title: response.title,
					text: response.message,
					type: response.command,
					timer: 3000,
					showConfirmButton: false
				}).catch(swal.noop);
			}

			if (action == 'replace') {
                $(response.js_class).html(response.html);
			}
			if (action == 'replacewith') {
				$(response.js_class).replaceWith(response.html);
			}

			if (action == 'append') {
				$(response.js_class).append(response.html);
			}

			if (action == 'scroll_to_top') {
				$("html, body").animate({
					scrollTop: ($('body').offset().top) + 'px'
				}, "slow");
            }
            
			if (action == 'toastr') {
				toastr.options = {
					"closeButton": true,
					"showDuration": "300",
					"hideDuration": "2000",
					"timeOut": "5000"
				};
				if (response.command == 'success') {
					toastr.success(response.message, response.title);
				} else if (response.command == 'info') {
					toastr.info(response.message, response.title);
				} else if (response.command == 'waring') {
					toastr.warning(response.message, response.title);
				} else if (response.command == 'error') {
					toastr.error(response.message, response.title);
				} else {
					toastr.info(response.message, response.title);
				}
			}

			if (action == 'replace_array_html') {
				$.each(response.responses, function (key, html) {
					$(key).html(html);
				});
            }
            
            if (action == 'rating') {
				$('.js-rating-social-wrap').find('.js-rating-point').html(response.rating_average);
				$('.js-rating-social-wrap').find('.js-rating-count').html(response.rating_sum);
				$('.js-rating-social-wrap').find('.js-rating-label-result').html(response.rating_text);
			}
            
            if (action == 'fill_province') {
                var mn_option = '';
                $.each(response.data.mn_provinces, function (key, item) {
					mn_option += '<option value="'+item.id+'">'+item.name+'</option>';
                });
                $('.js-opt-group-mn').html(mn_option);
                
                var mt_option = '';
                $.each(response.data.mt_provinces, function (key, item) {
					mt_option += '<option value="'+item.id+'">'+item.name+'</option>';
                });
                $('.js-opt-group-mt').html(mt_option);
			}

		});
	});
});