(function($) {
  $.fn.datepicker.language["vi"] = {
    days: ["Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
    daysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    daysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    months: [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12"
    ],
    monthsShort: [
      "Thg1",
      "Thg2",
      "Thg3",
      "Thg4",
      "Thg5",
      "Thg6",
      "Thg7",
      "Thg8",
      "Thg9",
      "Thg10",
      "Thg11",
      "Thg12"
    ],
    today: "Hôm nay",
    clear: "Xóa",
    dateFormat: "mm/dd/yyyy",
    timeFormat: "hh:ii aa",
    firstDay: 0
  };
})(jQuery);
