$(document).ready(function() {
    $(".js_btn_menu:not(.open)").click(function() {
        $(this).toggleClass("open");
        $("body").toggleClass("menu_mobile_opening");
        $("#webpage").addClass("mm-slideout");
        setTimeout(function() {
            $("body").toggleClass("menu_mobile_open_done");
        }, 300);
    });
    $(".panel-backdrop").click(function() {
        $("body").removeClass("menu_mobile_opening");
        $("body").removeClass("menu_mobile_open_done");
        $("#mn_icon").removeClass("open");
    });
    $('.js-scroll-to-top').on("click", function (e) {
        $('html,body').animate({ scrollTop: 0 }, '500');
    });
    $('.js-mobile-panel-expand').on("click", function (e) {
        $( this ).parent().toggleClass('active');
    })
});